module.exports = Object.freeze({
	"partners": [
		{
			"link": "https://www.walmart.com",
			"img": "/images/walmart_logo.jpeg"
		},
		{
			"link": "https://www.7-eleven.com",
			"img": "/images/711_image.png"
		},
		{
			"link": "https://www.bevmo.com/",
			"img": "/images/bevmo_image.png"
		},
		{
			"link": "https://www.drinks.com",
			"img": "/images/drinks_image.png"
		},
		{
			"link": "https://gianteagle.com",
			"img": "/images/giant_eagle.png"
		},
		{
			"link": "https://www.lowesfoods.com",
			"img": "/images/lowes_foods.png"
		},
		{
			"link": "https://www.traderjoes.com",
			"img": "/images/trader_joes_image.png"
		},
		{
			"link": "https://www.wegmans.com",
			"img": "/images/wegmans_image.png"
		}
	]
})
